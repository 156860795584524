import BannerGeneral from "../components/BannerGeneral";
import BlockBackgroundImage from "../components/BlockBackgroundImage";
import BlockImageCabecera from "../components/BlockImageCabecera";
import useContentful from "../hooks/use-contenful";
import Notification from "../components/Notification";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import ModalFixed from "../components/ModalFixed";
import Breadcumbs from "../components/Breadcumbs";
import { NavLink } from "react-router-dom";
import ConectUs from "../components/ConectUs";
import DistanciaOffer from "../components/DistanciaOffer";
import DistanciaPagos from "../components/Enlineapagos";
import FixedNotification from "../components/FixedNotification";
import { DistanciaNuevos, DistanciaNuevosFixed } from "../components/Pasos";
import Enlineapagos from "../components/Enlineapagos";

const query = `
query 
{
    videoTemplate(id: "rP59LhLF6ByzPLl8ZBpQd") {
      video{url}
      imageBlock1{url}
      contentBlock1{json}
      contentBlock2{json}
      contentBlock3{json}
      contentBlock4{json}
      imageBlock3{url}
      galleryCollection{
        items{url title}
         }
         gallery2Collection{
          items{url title}
           }
    }
}
`;
function Enlinea() {
  let { data, errors } = useContentful(query);
  if (errors)
    return <span>{errors.map((error) => error.message).join(",")}</span>;
  if (!data) return <p>Loading...</p>;

  return (
    <>
      <div className="margin relative">
        <BlockImageCabecera
          src={data.videoTemplate.imageBlock1.url}
          title={
            <>
              {documentToReactComponents(data.videoTemplate.contentBlock1.json)}
              <div className="margin">
                <a className="button1" href="#descuentos">
                  <span class="material-icons">chevron_right</span> Accede a
                  descuentos y becas
                </a>
            
              </div>
            </>
          }
        ></BlockImageCabecera>
        <ModalFixed
          title="¡Bienvenidos a la UTPL! 👋🏻"
          color="#4086b7"
          content={
            <>
              <iframe
                width="100%"
                height="500"
                allow="autoplay"
                src={data.videoTemplate.video.url}
              ></iframe>
            </>
          }
        ></ModalFixed>
      </div>{" "}
      <div className="relative">
        <Notification
          content={
            <>
              {documentToReactComponents(data.videoTemplate.contentBlock2.json)}
            </>
          }
        ></Notification>

        <DistanciaNuevosFixed></DistanciaNuevosFixed>
      </div>
      <Breadcumbs
        content={
          <>
            <NavLink to="/en-linea">Modalidad en línea</NavLink>
          </>
        }
      ></Breadcumbs>
      <DistanciaOffer
        title={
          <>
            {documentToReactComponents(data.videoTemplate.contentBlock4.json)}
          </>
        }
        subtitle="Elige tu carrera"
      ></DistanciaOffer>
      <Enlineapagos></Enlineapagos>
      <ConectUs
        lema="Descubre contenido exclusivo, mantente al tanto de las últimas novedades y únete a nuestra comunidad de estudiantes en línea y a distancia."
        instagram="https://www.instagram.com/utplenlinea/"
        facebook="https://www.facebook.com/utplenlinea/"
        tiktok="https://www.tiktok.com/@utplenlinea"
      ></ConectUs>
      <BlockBackgroundImage
        src={data.videoTemplate.imageBlock3.url}
        content={documentToReactComponents(
          data.videoTemplate.contentBlock3.json
        )}
      ></BlockBackgroundImage>
      <BannerGeneral></BannerGeneral>
    </>
  );
}

export default Enlinea;

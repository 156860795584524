import useContentful from "../hooks/use-contenful";
import { NavLink } from "react-router-dom";
import Breadcumbs from "../components/Breadcumbs";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import BlockRightImage from "../components/BlockRightImage";
import BlockLeftImage from "../components/BlockLeftImage";
import Accordion from "../components/Accordion";

import {
  DistanciaContinuos,
  PresencialInscripcion,
  PresencialNuevos,
  PresencialContinuos,
  PosgradoPostulacion,
  PosgradoNuevos,
  DistanciaNuevos,
} from "../components/Pasos";
import BannerGeneral from "../components/BannerGeneral";

const query = `
query 
{
    videoTemplate(id: "3uYjEKb33bvoCcIsRnRMi7") {
      contentBlock1{json}
      contentBlock2{json}
      contentBlock3{json}
      contentBlock4{json}
       contentBlock5{json}
      contentBlock6{json}
      contentBlock7{json}
      contentBlock8{json}
      contentBlock9{json}
      contentBlock10{json}
    }
  
}
`;

function Matriculas() {
  let { data, errors } = useContentful(query);
  if (errors)
    return <span>{errors.map((error) => error.message).join(",")}</span>;
  if (!data) return <p>Loading...</p>;

  return (
    <div className="wrapper-matricula">
      <div className="margin">
        <Breadcumbs
          content={
            <>
              <NavLink to="/matriculas">Matrículas</NavLink>
            </>
          }
        ></Breadcumbs>
      </div>

      <div className="layout6">
        <center>
          {documentToReactComponents(data.videoTemplate.contentBlock1.json)}
        </center>
      </div>

      <div className="padding2">
        <BlockLeftImage
          src="https://utpl.edu.ec/recursos/img/linea.gif"
          alt="utpl"
          content={
            <>
              {documentToReactComponents(data.videoTemplate.contentBlock2.json)}
              <DistanciaNuevos></DistanciaNuevos>
              <DistanciaContinuos></DistanciaContinuos>
            </>
          }
        ></BlockLeftImage>
        <div className="layout8">
          <Accordion
            title="Fechas de matrícula"
            content={documentToReactComponents(
              data.videoTemplate.contentBlock3.json
            )}
          ></Accordion>

          <Accordion
            title="Calendario académico"
            content={documentToReactComponents(
              data.videoTemplate.contentBlock4.json
            )}
          ></Accordion>
        </div>
      </div>

      <div className="graybg padding2">
        <BlockRightImage
          src="https://utpl.edu.ec/recursos/img/presencial.gif"
          alt="utpl"
          content={
            <>
              {documentToReactComponents(data.videoTemplate.contentBlock5.json)}
              <PresencialInscripcion></PresencialInscripcion>
              <PresencialNuevos></PresencialNuevos>
              <PresencialContinuos></PresencialContinuos>
            </>
          }
        ></BlockRightImage>
        <div className="layout8">
          <Accordion
            title="Fechas de matrícula"
            content={
              <>
                {documentToReactComponents(
                  data.videoTemplate.contentBlock6.json
                )}
                <Accordion
                  title="Oferta periodo extraordinario febrero - marzo 2025"
                  content={
                    <>
                      <ul>
                        <li>
                          <p>
                            <a
                              target="_blank"
                              href="https://utpl.edu.ec/documentos/oferta/pe12025/salud.pdf"
                            >
                              Facultad de Ciencias de la Salud
                            </a>
                          </p>{" "}
                        </li>

                        <li>
                          <p>
                            <a
                              target="_blank"
                              href="https://utpl.edu.ec/documentos/oferta/pe12025/naturales.pdf"
                            >
                              Facultad de Ciencias Exactas y Naturales
                            </a>
                          </p>{" "}
                        </li>

                        <li>
                          <p>
                            <a
                              target="_blank"
                              href="https://utpl.edu.ec/documentos/oferta/pe12025/ingenieria.pdf"
                            >
                              Facultad de Ingenierías y Arquitectura
                            </a>
                          </p>{" "}
                        </li>
                      </ul>
                    </>
                  }
                ></Accordion>
           
              </>
            }
          ></Accordion>
          <Accordion
            title="Calendario académico"
            content={documentToReactComponents(
              data.videoTemplate.contentBlock7.json
            )}
          ></Accordion>
        </div>
      </div>

      <div className=" padding2">
        <BlockLeftImage
          src="https://utpl.edu.ec/recursos/img/posgrados.gif"
          alt="utpl"
          content={
            <>
              {documentToReactComponents(data.videoTemplate.contentBlock8.json)}
              <PosgradoPostulacion></PosgradoPostulacion>
              <PosgradoNuevos></PosgradoNuevos>
            </>
          }
        ></BlockLeftImage>
        <div className="layout8">
          <Accordion
            title="Fechas de matrícula"
            content={documentToReactComponents(
              data.videoTemplate.contentBlock9.json
            )}
          ></Accordion>
          <Accordion
            title="Calendario académico"
            content={documentToReactComponents(
              data.videoTemplate.contentBlock10.json
            )}
          ></Accordion>
        </div>
      </div>
      <BannerGeneral></BannerGeneral>
    </div>
  );
}

export default Matriculas;

import useContentful from "../hooks/use-contenful";
import "../css/academicoffer.css";
import { useState } from "react";
import { motion } from "framer-motion";

function PosgradoView(props) {
  const [state, setState] = useState(0);
  const [stateheight, setStateHeight] = useState(0);
  const [heightWrapper, setheightWrapper] = useState({
    height: "1000px",
    overflow: "hidden",
  });
  let key = "";
  let facultad = "";
  let query = "";
  let tipoprograma = "";
  let modalidad = "";
  let oferta = "";

  if (props.keyword) {
    key = props.keyword;
  } else {
    key = "0";
  }
  if (props.modalidad) {
    modalidad = props.modalidad;
  } else {
    modalidad = "0";
  }

  if (props.facultad) {
    facultad = props.facultad;
  } else {
    facultad = "";
  }

  if (props.tipo_programa) {
    tipoprograma = props.tipo_programa;
  } else {
    tipoprograma = "";
  }

  if (props.oferta) {
    oferta = props.oferta;
  } else {
    oferta = "";
  }

  if (key === "0" && modalidad === "0") {
    query = `query 
    {
     academicProgramsCollection(where:
      {
     tipoDePrograma_contains:"Posgrado",
     tipoDePosgrado_contains:"${tipoprograma}",
     campoconocimientoPosgrado_contains: "${facultad}",
     ofertaActual_contains:"${oferta}",
    }order:[ofertaActual_DESC,nombre_ASC ]){items{
       sys{id}
       nombre
       tipoDePrograma
       tipoDePosgrado
       campoconocimientoPosgrado
       tipoPosgrado
       periodo
       facultad
       modalidad
       duracion
       inicio
       enlaceAPrograma
       keywords
        ofertaActual
        tag
       
     } 
     }
     }  
    `;
  } else if (key === "0" && modalidad !== "0") {
    query = `query 
    {
     academicProgramsCollection(where:
      {
    tipoDePrograma_contains:"Posgrado",
    tipoDePosgrado_contains:"${tipoprograma}",
    campoconocimientoPosgrado_contains: "${facultad}",
    ofertaActual_contains:"${oferta}",
    modalidad_contains_all:"${modalidad}"

    }order:[ofertaActual_DESC,nombre_ASC ]){items{
       sys{id}
       nombre
       tipoDePrograma
       tipoDePosgrado
       campoconocimientoPosgrado
       tipoPosgrado
       periodo
       facultad
       modalidad
       duracion
       inicio
       enlaceAPrograma
       keywords
        ofertaActual
        tag
       
     } 
     }
     }  
    `;
  } else if (key !== "0" && modalidad === "0") {
    query = `query 
    {
     academicProgramsCollection(where:
      {
    tipoDePrograma_contains:"Posgrado",
    tipoDePosgrado_contains:"${tipoprograma}",
    campoconocimientoPosgrado_contains: "${facultad}",
    ofertaActual_contains:"${oferta}",
    keywords_contains_all:"${key}"
    }order:[ofertaActual_DESC,nombre_ASC ]){items{
       sys{id}
       nombre
       tipoDePrograma
       tipoDePosgrado
       campoconocimientoPosgrado
       tipoPosgrado
       periodo
       facultad
       modalidad
       duracion
       inicio
       enlaceAPrograma
       keywords
        ofertaActual
        tag
       
     } 
     }
     }  
    `;
  } else if (key !== "0" && modalidad !== "0") {
    query = `query 
    {
     academicProgramsCollection(where:
      {
    tipoDePrograma_contains:"Posgrado",
    tipoDePosgrado_contains:"${tipoprograma}",
    campoconocimientoPosgrado_contains: "${facultad}",
    ofertaActual_contains:"${oferta}",
    modalidad_contains_all:"${modalidad}"
    keywords_contains_all:"${key}"
    }order:[ofertaActual_DESC,nombre_ASC ]){items{
       sys{id}
       nombre
       tipoDePrograma
       tipoDePosgrado
       campoconocimientoPosgrado
       tipoPosgrado
       periodo
       facultad
       modalidad
       duracion
       inicio
       enlaceAPrograma
       keywords
        ofertaActual
        tag
       
     } 
     }
     }  
    `;
  }

  function IsModalidad(modalidad) {
    return (
      <p>
        <b>Modalidad: </b>
        {modalidad[0].toLowerCase()}
      </p>
    );
  }

  function IsTipo(tipo) {
    try {
      if (tipo)
        return (
          <p>
            <b>Tipo: </b>
            {tipo}
          </p>
        );
      else return tipo;
    } catch {
      return tipo;
    }
  }

  function IsInicio(inicio) {
    try {
      if (inicio[1]) {
        return (
          <>
            <b>Inicio de clases:</b>
            <ul>
              <li>
                <p>{inicio[0]}</p>
              </li>
              <li>
                <p>{inicio[1]}</p>
              </li>
            </ul>
          </>
        );
      } else {
        return (
          <>
            <p>
              <b>Inicio de clases: </b>
              {inicio[0]}
            </p>
          </>
        );
      }
    } catch (error) {
      return "";
    }
  }

  function Cupos(cupos) {
    if (cupos === "si") {
      return <p className="tag-cupos">Postulaciones abiertas</p>;
    }
  }

  function ViewGrid() {
    setState(0);
  }

  function ViewList() {
    setState(1);
  }
  function handleActiveMore() {
    setheightWrapper({ height: "auto", overflow: "none" });
    setStateHeight(1);
  }

  function IsTag(data) {
    if (data && data.trim && data.trim() !== "") {
      return (
        <p className="tag-2">{data}</p>
      );
    }
    return null; // Renderiza nada si data está vacío
  }


  let { data, errors } = useContentful(query);
  if (errors)
    return <span>{errors.map((error) => error.message).join(",")}</span>;
  if (!data) return <p>Loading...</p>;

  return (
    <>
      <div className="wrapper-view">
        {state === 0 && (
          <div>
            <p onClick={ViewList}>
              Vista de lista <span class="material-icons">view_list</span>
            </p>
          </div>
        )}
        {state === 1 && (
          <div>
            <p onClick={ViewGrid}>
              Vista de cuadrícula <span class="material-icons">grid_view</span>
            </p>
          </div>
        )}
      </div>
      <br></br>

      {state === 0 && (
        <>
          <div style={heightWrapper}>
            <div className="wrapper-academicoffer">
              {data?.academicProgramsCollection.items?.map((info) => (
                <>
                  <div key={info.sys.id}>
                    <div className="layout8">
                      <div className="flex">
                      {Cupos(info.ofertaActual)}
                        {IsTag(info.tag)}
                      </div>
                    
                     

                      <h3>{info.nombre}</h3>
                      <p>
                        <b>Programa: </b>
                        {info.tipoDePosgrado}
                      </p>

                      {IsTipo(info.tipoPosgrado)}

                      {IsModalidad(info.modalidad)}

                      <p>
                        <b>Duración:</b> {info.duracion}
                      </p>

                      <p>
                        <b>Campo del conocimiento: </b>
                        {info.campoconocimientoPosgrado}
                      </p>

                      <p>
                        <b>Facultad: </b>
                        {info.facultad}
                      </p>

                      
                      <p>
                        <b>Periodo: </b>
                        {info.periodo}
                      </p>

                      <p>{IsInicio(info.inicio)}</p>

                      <div className="wrapper-link">
                        <a
                          className="link-offer"
                          href={info.enlaceAPrograma}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Ver programa
                          <span className="material-icons">
                            arrow_forward_ios{" "}
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
          {stateheight === 0 && (
            <motion.div
              initial={{ x: -100, opacity: 0 }}
              whileInView={{
                x: 0,
                opacity: 1,
                transition: {
                  type: "spring",
                  stiffness: 200,
                },
              }}
            >
              <div className="section-moreinfo">
                <div className="wrapper-moreinfo">
                  <div className="wrapper-moreinfo" onClick={handleActiveMore}>
                    Ver todos los programas
                    <span className="material-icons">add</span>
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </>
      )}

      {state === 1 && (
        <div className="wrapper-academicofferlist layout9">
          {data?.academicProgramsCollection.items?.map((info) => (
            <>
              <div key={info.sys.id}>
                <a href={info.enlaceAPrograma} target="_blank" rel="noreferrer">
                  <span class="material-icons">chevron_right</span>{" "}
                  {info.nombre}
                </a>
              </div>
            </>
          ))}
        </div>
      )}
    </>
  );
}

export default PosgradoView;

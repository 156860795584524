import { useState } from "react";
import { motion } from "framer-motion";
import AcademicOffer from "../components/AcademicOffer";
import Select from "react-select";
import "../css/academicoffer.css";
import { NavLink } from "react-router-dom";

function BlockAcademicOffer() {
  const [selectedOption, setSelectedOption] = useState(undefined);
  const [selectedModalidad, setSelectedModalidad] = useState(undefined);
  const [dataarea, setDataArea] = useState("");

  const optionsprograma = [
    { value: "Carrera de grado", label: "Carrera de grado" },
    { value: "Carrera tecnológica", label: "Carrera tecnológica" },
    { value: "Posgrado", label: "Posgrado" },
    { value: "Formación Permanente", label: "Formación permanente" },
    { value: 0, label: "-" },
  ];
  const optionsmodalidad = [
    { value: "En línea", label: "En línea" },
    { value: "Presencial", label: "Presencial" },
    { value: "a Distancia", label: "a Distancia" },

    { value: 0, label: "-" },
  ];

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const handleChangeModalidad = (selectedModalidad) => {
    setSelectedModalidad(selectedModalidad);
  };

  return (
    <>
      <div className="wrapper-titleoffer padding">
        <motion.div
          initial={{ y: 200, opacity: 0 }}
          whileInView={{
            y: 0,
            opacity: 1,
            transition: {
              type: "spring",
              stiffness: 200,
            },
          }}
        >
          <h2>
            Descubre una <b>experiencia universitaria innovadora</b> y alcanza
            tus
            <b> metas académicas con nosotros</b>, en el momento y lugar que
            prefieras.
          </h2>

          <center> <h3><b>Matrículas abiertas</b></h3></center>

         
         
        </motion.div>
      </div>

      <div className="wrapper-offer layout8">
        <div>
          <h2>Nivel de formación</h2>
          <div>
            <Select
              defaultValue={selectedOption}
              onChange={handleChange}
              options={optionsprograma}
            />
          </div>
        </div>

        <div>
          <h2>Modalidad</h2>
          <div>
            <Select
              defaultValue={selectedModalidad}
              onChange={handleChangeModalidad}
              options={optionsmodalidad}
            />
          </div>
        </div>

        <div>
          <h2>Área de interés</h2>

          <div className="wrapper-inputoffer">
            <input
              className="input-academicoffer"
              name="area"
              value={dataarea}
              onChange={(e) => setDataArea(e.target.value)}
              placeholder="Ejemplo: empresas"
            ></input>
            <span className="material-icons">search</span>
          </div>
        </div>
      </div>

      <div className="layout9">
        <AcademicOffer
          modalidad={selectedModalidad}
          programa={selectedOption}
          keyword={dataarea.trimEnd().toLowerCase()}
        ></AcademicOffer>
      </div>

      <div className="layout8">
        <div className="wrapper-contentoffer">
          <motion.div
            initial={{ y: 100, opacity: 0 }}
            whileInView={{
              y: 0,
              opacity: 1,
              transition: {
                delay: 0.1,
              },
            }}
          >
            <NavLink to="/en-linea">
              <img
                src="https://utpl.edu.ec/recursos/img/img1.webp"
                alt="utpl"
              ></img>
              <div className="title">
                <div className="layout9">
                  <p className="tag1">Carreras a distancia y en línea</p>
                  <p className="tag"> 20% de descuento</p>
                
                </div>
              </div>
            </NavLink>
          </motion.div>

          <motion.div
            initial={{ y: 100, opacity: 0 }}
            whileInView={{
              y: 0,
              opacity: 1,
              transition: {
                delay: 0.2,
              },
            }}
          >
            <NavLink to="/presencial">
              <img
                src="https://utpl.edu.ec/recursos/img/img2.webp"
                alt="utpl"
              ></img>{" "}
              <div className="title">
                <div className="layout9">
                  <p className="tag1">Carreras presencial</p>
                  <p className="tag"> 8% de descuento</p>
               
                </div>
              </div>
            </NavLink>
          </motion.div>

          <motion.div
            initial={{ y: 100, opacity: 0 }}
            whileInView={{
              y: 0,
              opacity: 1,
              transition: {
                delay: 0.3,
              },
            }}
          >
            <NavLink to="/tecnologias">
              <img
                src="https://utpl.edu.ec/recursos/img/img3.webp"
                alt="utpl"
              ></img>{" "}
              <div className="title">
                <div className="layout9">
                  <p className="tag1">Carreras tecnológicas</p>
                  <p className="tag">20% de descuento</p>

                  
                </div>
              </div>
            </NavLink>
          </motion.div>

          <motion.div
            initial={{ y: 100, opacity: 0 }}
            whileInView={{
              y: 0,
              opacity: 1,
              transition: {
                delay: 0.4,
              },
            }}
          >
            <NavLink to="/posgrados">
              <img
                src="https://utpl.edu.ec/recursos/img/img4.webp"
                alt="utpl"
              ></img>{" "}
              <div className="title">
                <div className="layout9">
                  <p className="tag1">Posgrados</p>
                  <p className="tag">10% de descuento al pagar la totalidad del programa</p>
               
                </div>
              </div>
            </NavLink>
          </motion.div>

          <motion.div
            initial={{ y: 100, opacity: 0 }}
            whileInView={{
              y: 0,
              opacity: 1,
              transition: {
                delay: 0.4,
              },
            }}
          >
            <NavLink to="/formacionpermanente">
              <img
                src="https://utpl.edu.ec/recursos/img/img5.webp"
                alt="utpl"
              ></img>{" "}
              <div className="title">
                <p className="tag1">Formación permanente</p>
              </div>
            </NavLink>
          </motion.div>
        </div>
      </div>
    </>
  );
}

export default BlockAcademicOffer;

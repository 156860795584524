
import BannerGeneral from "../components/BannerGeneral";
import BlockAcademicOffer from "../components/BlockAcademicOffer";

import { NavLink } from "react-router-dom";
import Breadcumbs from "../components/Breadcumbs";

function Oferta() {
  return (
    <>
      <div className="margin ">
        <Breadcumbs
          content={
            <>
              <NavLink to="/oferta">Oferta académica</NavLink>
            </>
          }
        ></Breadcumbs>
      </div>

      <BlockAcademicOffer></BlockAcademicOffer>


      <BannerGeneral></BannerGeneral>
    </>
  );
}

export default Oferta;

import FixedHeader from "../../components/FixedHeader";
import { motion } from "framer-motion";
import img from "../../img/titulo-validable.png";
import Block3Columns from "../../components/Block3Columns";

import Block2Columns from "../../components/Block2ColumnsText";

import Modal from "../../components/Modal";
import Accordion from "../../components/Accordion";

import gif1 from "../../img/iconstitulovalidable/reconocimiento.gif";
import gif2 from "../../img/iconstitulovalidable/experiencia.gif";
import gif3 from "../../img/iconstitulovalidable/certificado.gif";
import Gallery from "../../components/Gallery";

import portada_notas from "../../img/iconstitulovalidable/notas/portada.png";
import paso1_notas from "../../img/iconstitulovalidable/notas/paso1.png";
import paso2_notas from "../../img/iconstitulovalidable/notas/paso2.png";
import paso3_notas from "../../img/iconstitulovalidable/notas/paso3.png";
import paso4_notas from "../../img/iconstitulovalidable/notas/paso4.png";

import portada_titulo from "../../img/iconstitulovalidable/titulo/portada.png";
import paso1_titulo from "../../img/iconstitulovalidable/titulo/paso1.png";
import paso2_titulo from "../../img/iconstitulovalidable/titulo/paso2.png";
import paso3_titulo from "../../img/iconstitulovalidable/titulo/paso3.png";
import paso4_titulo from "../../img/iconstitulovalidable/titulo/paso4.png";

function TituloValidable() {
  const gallery1 = [
    { url: portada_notas },
    { url: paso1_notas },
    { url: paso2_notas },
    { url: paso3_notas },
    { url: paso4_notas },
  ];

  const gallery2 = [
    { url: portada_titulo },
    { url: paso1_titulo },
    { url: paso2_titulo },
    { url: paso3_titulo },
    { url: paso4_titulo },
  ];
  return (
    <>
      <FixedHeader
        src={img}
        span2={
          <>
            Título <b>UTPL</b>
          </>
        }
        span3="validable en"
        span4="Estados Unidos"
      ></FixedHeader>
      <div className=" padding bluebg">
        <div className="layout8">
          <center>
            <motion.h2
              initial={{ y: 150, opacity: 0 }}
              whileInView={{
                y: 0,
                opacity: 1,
                transition: {
                  type: "spring",
                  stiffness: 200,
                },
              }}
            >
              Accede a <b>oportunidades globales</b>
            </motion.h2>

            <motion.div
              initial={{ y: -150, opacity: 0 }}
              whileInView={{
                y: 0,
                opacity: 1,
                transition: {
                  type: "spring",
                  stiffness: 200,
                  delay: 0.1,
                },
              }}
            >
              <h3>
                <b>Continúa con tus estudios</b> de maestría o doctorado en
                Estados Unidos
              </h3>
              <p>
                La validación de títulos aplica para todas las carreras de grado
                y programas de posgrado ofrecidos por la UTPL.
              </p>{" "}
              <div className="padding2 flex-center iconos-validable">
                <div>
                  <span class="material-icons">schedule</span>{" "}
                  <p>
                    <b>Tiempo del proceso</b>
                  </p>
                  <p>3 a 4 meses</p>
                </div>

                <div>
                  <span class="material-icons">payments</span>{" "}
                  <p>
                    <b>Costo referencial</b>
                  </p>
                  <p>400 dólares</p>
                </div>
              </div>
              <h3>
                <a href="#validacion"> ¡Solicita ya la validación! </a>
              </h3>
            </motion.div>
          </center>
        </div>
      </div>

      <div className="padding">
        <h2 className="layout8">
          Beneficios para los <b>graduados UTPL </b>
        </h2>
        <Block3Columns
          col1={
            <>
              <div className="icon100">
                <img src={gif1} alt="utpl"></img>
              </div>

              <p>
                <h3>
                  <b>Reconocimiento</b> profesional destacado{" "}
                </h3>
              </p>
              <p>
                Esta validación impulsa tu competitividad en un mercado global
                en constante evolución. Con un título respaldado por una
                institución de prestigio, te conviertes en un profesional
                altamente valorado.{" "}
              </p>
            </>
          }
          col2={
            <>
              <div className="icon100">
                <img src={gif2} alt="utpl"></img>
              </div>
              <p>
                <h3>
                  Acceso a<b> mejores oportunidades</b> laborales{" "}
                </h3>
              </p>
              <p>
                Gracias a una formación integral y de alto nivel, estarás
                preparado para asumir roles estratégicos en empresas de
                prestigio, tanto a nivel nacional como internacional. Además,
                contarás con herramientas que incrementarán tus posibilidades de
                ascender y consolidarte en tu área profesional.{" "}
              </p>
            </>
          }
          col3={
            <>
              <div className="icon100">
                <img src={gif3} alt="utpl"></img>
              </div>
              <p>
                <h3>
                  Impulsa tu <b>visibilidad </b>académica{" "}
                </h3>
              </p>
              <p>
                La certificación de la UTPL incrementa tu reputación en el
                ámbito académico y profesional, posicionándote como un candidato
                ideal para participar en proyectos de investigación,
                publicaciones especializadas y consultorías.{" "}
              </p>
            </>
          }
        ></Block3Columns>
      </div>

      <div className="graybg padding" id="validacion">
        <div className="layout8">
          <center>
            <h2>
              ¿Cómo solicitar<b> la validación?</b>{" "}
            </h2>
            <p>Si eres UTPL Alumni, sigue estos pasos:</p>
          </center>
        </div>

        <div>
          <Block3Columns
            col1={
              <>
                <h3>
                  1. Obtén tus <b>documentos oficiales</b>
                </h3>

                <p>
                  Para llevar a cabo este proceso, debes solicitar los
                  certificados de <b> notas y del título en español e inglés.</b>
                </p>
              </>
            }
            col2={
              <div className="margin">
                {" "}
                <Accordion
                  title="Certificado de notas traducido"
                  content={
                    <>
                      {" "}
                      <p>
                        No necesitas presentar documentación. El certificado
                        será entregado en ambos idiomas, español e inglés.
                      </p>
                      <p>
                        <b>Costo:</b> 60 dólares
                      </p>
                    </>
                  }
                ></Accordion>
                <Modal
                  title={<>Proceso certificado de notas 🖊</>}
                  content={
                    <>
                      <Gallery gallery={gallery1}> </Gallery>
                    </>
                  }
                ></Modal>
                <a
                  href="https://studentssb-prod.srv.utpl.edu.ec/StudentSelfService"
                  target="_blank"
                >
                  Solicitar certificado de notas traducido
                </a>
              </div>
            }
            col3={
              <div className="margin">
                {" "}
                <Accordion
                  title="Certificado de título traducido"
                  content={
                    <>
                      <p>
                        Para este proceso, es necesario contar con una copia de
                        tu título original en formato PDF. El certificado
                        corresponderá a la traducción oficial de tu título
                        original.
                      </p>
                      <p>
                        <b>Costo:</b> 15 dólares por hoja.
                      </p>
                    </>
                  }
                ></Accordion>
                <Modal
                  title={<>Proceso para solicitar título traducido 🖊</>}
                  content={
                    <>
                      <Gallery gallery={gallery2}></Gallery>
                    </>
                  }
                ></Modal>
                <a
                  href="https://studentssb-prod.srv.utpl.edu.ec/StudentSelfService"
                  target="_blank"
                >
                  Solicitar certificado de título traducido
                </a>
              </div>
            }
          ></Block3Columns>

          <Block2Columns
            col1={
              <>
                <h3>
                  2. Llena el <b>formulario</b> en línea
                </h3>

                <p>
                  Completa la información requerida para iniciar tu trámite.
                </p>

                <a href="https://forms.office.com/pages/responsepage.aspx?id=qknrbm1D5kO-zbvfeeUHfUpaIX--BQBPo5gSxoiZDudURVM2Q0xKNFZUWUM2V08xODdFUEVUV1Y1Sy4u&route=shorturl">Regístrate aquí</a>
              </>
            }
            col2={
              <>
                <h3>
                  3. Espera nuestro <b>contacto</b>
                </h3>

                <p>
                  Nos pondremos en contacto contigo para proporcionarte todos
                  los detalles y seguir adelante con tu solicitud.
                </p>
              </>
            }
          ></Block2Columns>
        </div>

        <div className="layout8 margin">
          <h2>
            ¿Necesitas <b>ayuda?</b>
          </h2>
          <p>
            Estamos aquí para asistirte. Conéctate con nosotros a través de
            nuestros canales de comunicación.
          </p>
          <a className="button" href="/contacto">
            Haz clic aquí
          </a>
        </div>
      </div>

      <div></div>
    </>
  );
}

export default TituloValidable;

import { useState, useEffect } from "react";
import "../css/fixedheader.css";
import { motion } from "framer-motion";

function FixedHeader(props) {
  const [width, setWidth] = useState(window.innerWidth);
  const mystyle = {
    backgroundImage: `url(${props.src})`,
  };

  const updateWindowDimensions = () => {
    const newW = window.innerWidth;
    setWidth(newW);
    // console.log('updating height');
  };

  useEffect(() => {
    window.addEventListener("resize", updateWindowDimensions);
    // console.log("give height", height);
  }, []);

  return (
    <>
      <div className="wrapper-fixedheader" style={mystyle}>
        {width > 900 && (
          <video autoPlay muted loop height="100%">
            <source src={props.video} type="video/mp4" />
            Sorry, your browser doesn't support embedded videos.
          </video>
        )}
      </div>

      {width > 900 && (
        <div className="wrapper-lemaheader">
          <div>
            <div className="bloque1 ">
              <motion.p
                initial={{ x: -50, opacity: 0 }}
                whileInView={{
                  x: 0,
                  opacity: 1,
                  transition: {
                    type: "spring",
                    stiffness: 200,
                    delay: 0.8,
                  },
                }}
              >
                {props.span}
              </motion.p>
            </div>
            <div className="bloque2">
              <motion.p
                className="span0"
                initial={{ x: -50, opacity: 0 }}
                whileInView={{
                  x: 0,
                  opacity: 1,
                  transition: {
                    type: "spring",
                    stiffness: 200,
                  },
                }}
              >
                {props.span0}
              </motion.p>

              <motion.p
                className="span1"
                initial={{ x: -50, opacity: 0 }}
                whileInView={{
                  x: 0,
                  opacity: 1,
                  transition: {
                    type: "spring",
                    stiffness: 200,
                    delay: 0.2,
                  },
                }}
              >
                {props.span1}
              </motion.p>

              <motion.p
                className="span2"
                initial={{ x: 50, opacity: 0 }}
                whileInView={{
                  x: 0,
                  opacity: 1,
                  transition: {
                    type: "spring",
                    stiffness: 200,
                    delay: 0.3,
                  },
                }}
              >
                {props.span2}
              </motion.p>

              <motion.p
                className="span3"
                initial={{ y: -50, opacity: 0 }}
                whileInView={{
                  y: 0,
                  opacity: 1,
                  transition: {
                    type: "spring",
                    stiffness: 200,
                    delay: 0.4,
                  },
                }}
              >
                {props.span3}
              </motion.p>

              <motion.p
                className="span4"
                initial={{ scale: 1.5, opacity: 0 }}
                whileInView={{
                  scale: 1,
                  opacity: 1,
                  transition: {
                    type: "spring",
                    stiffness: 200,
                    delay: 0.8,
                  },
                }}
              >
                {props.span4}
              </motion.p>
            </div>
          </div>
        </div>
      )}
      <motion.div
        initial={{ y: 10 }}
        animate={{ y: 0, opacity: 0.8 }}
        transition={{ type: "spring", repeat: Infinity }}
        className="wrapper-contentheader"
      >
        <p>Desliza para continuar con tu experiencia</p>
        <span className="material-icons">mouse</span>
      </motion.div>
    </>
  );
}

export default FixedHeader;

import "../css/notification.css";
import { motion } from "framer-motion";
import line1 from "../img/line1.png";
import line2 from "../img/line2.png";
function Notification(props) {
  return (
    <>
      <div className="wrapper-notification padding2 relative">
        <div className="layout6">
        <motion.div
          initial={{ y: 200, opacity: 0 }}
          whileInView={{
            y: 0,
            opacity: 1,
            transition: {
              type: "spring",
              stiffness: 200,
            },
          }}
        >
          <p>{props.content}</p>
        </motion.div>
        <motion.div
          initial={{ y: 100, opacity: 0 }}
          whileInView={{
            y: 0,
            opacity: 1,
            transition: {
              type: "spring",
              stiffness: 200,
       
            },
          }}
        >
      
        </motion.div>
        <div className="bg-line1">
          <motion.div
            initial={{ scale: 0, opacity: 0 }}
            whileInView={{
              scale: 1,
              opacity: 1,
              transition: {
                type: "spring",
                damping: 50,
                duration: 1,
              },
            }}
          >
            <img className="line1" src={line1} alt="utpl"></img>
          </motion.div>
        </div>
        <div className="bg-line2">
          <motion.div
            initial={{ scale: 0, opacity: 0 }}
            whileInView={{
              scale: 1,
              opacity: 1,
              transition: {
                type: "spring",
                damping: 50,
                duration: 0.5,
                delay: 0.1,
              },
            }}
          >
            <img className="line2" src={line2} alt="utpl"></img>
          </motion.div>
        </div>

        <div className="bg-line3">
          <motion.div
            initial={{ scale: 0, opacity: 0 }}
            whileInView={{
              scale: 1,
              opacity: 1,
              transition: {
                type: "spring",
                damping: 50,
                duration: 0.5,
                delay: 0.1,
              },
            }}
          >
            <img className="line2" src={line2} alt="utpl"></img>
          </motion.div>
        </div>
      </div></div>
    </>
  );
}

export default Notification;

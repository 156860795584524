
import BannerGeneral from "../components/BannerGeneral";
import BlockHeramientas from "../components/BlockHerramientas";
import useContentful from "../hooks/use-contenful";
import { NavLink } from "react-router-dom";
import Breadcumbs from "../components/Breadcumbs";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import PresencialPagos from "../components/PresencialPagos";

const query = `
query 
{
    videoTemplate(id: "5FkZdE2BplW46hJSSylduN") {
      imageBlock1{url, title}
      contentBlock1{json}
      imageBlock2{url, title}
      contentBlock2{json}
      imageBlock3{url, title}
      contentBlock3{json}
      imageBlock4{url, title}
      contentBlock4{json}
      imageBlock5{url, title}
      contentBlock5{json}
      imageBlock6{url, title}
      contentBlock6{json}
      imageBlock7{url, title}
      contentBlock7{json}
     
    }
}
`;
function ModeloPresencial() {
  let { data, errors } = useContentful(query);
  if (errors)
    return <span>{errors.map((error) => error.message).join(",")}</span>;
  if (!data) return <p>Loading...</p>;

  return (
    <>
      <div className="margin">
        <Breadcumbs
          content={
            <>
              <NavLink to="/presencial">Modalidad presencial</NavLink>
              <span class="material-icons">chevron_right</span>
              <NavLink to="/estudiar-en-loja">Estudiar en Loja</NavLink>
            </>
          }
        ></Breadcumbs>
      </div>

      <div className="layout8  ">
        <h2 className="width500">
          ¿Por qué estudiar en <b>modalidad presencial en la UTPL?</b>
        </h2>
      </div>

      <BlockHeramientas
        bgimage="#2D9AC6"
        src={data.videoTemplate.imageBlock1.url}
        alt={data.videoTemplate.imageBlock1.title}
        content={documentToReactComponents(
          data.videoTemplate.contentBlock1.json
        )}
      ></BlockHeramientas>

      <div className="graybg">
        <BlockHeramientas
          bgimage="#2DC65B"
          src={data.videoTemplate.imageBlock2.url}
          alt={data.videoTemplate.imageBlock2.title}
          content={documentToReactComponents(
            data.videoTemplate.contentBlock2.json
          )}
        ></BlockHeramientas>
      </div>

      <BlockHeramientas
        bgimage="#B91461"
        src={data.videoTemplate.imageBlock3.url}
        alt={data.videoTemplate.imageBlock3.title}
        content={documentToReactComponents(
          data.videoTemplate.contentBlock3.json
        )}
      ></BlockHeramientas>

      <div className="graybg">
        <BlockHeramientas
          bgimage="#14B969"
          src={data.videoTemplate.imageBlock4.url}
          alt={data.videoTemplate.imageBlock4.title}
          content={documentToReactComponents(
            data.videoTemplate.contentBlock4.json
          )}
        ></BlockHeramientas>
      </div>

      <BlockHeramientas
        bgimage="#1498B9"
        src={data.videoTemplate.imageBlock5.url}
        alt={data.videoTemplate.imageBlock5.title}
        content={documentToReactComponents(
          data.videoTemplate.contentBlock5.json
        )}
      ></BlockHeramientas>

      <div className="graybg">
        <BlockHeramientas
          bgimage="#1498B9"
          src={data.videoTemplate.imageBlock6.url}
          alt={data.videoTemplate.imageBlock6.title}
          content={documentToReactComponents(
            data.videoTemplate.contentBlock6.json
          )}
        ></BlockHeramientas>
      </div>
      <BlockHeramientas
        bgimage="#1498B9"
        src={data.videoTemplate.imageBlock7.url}
        alt={data.videoTemplate.imageBlock7.title}
        content={documentToReactComponents(
          data.videoTemplate.contentBlock7.json
        )}
      ></BlockHeramientas>

      <div className="servicios-presencial padding ">
        <h3 className="layout8">Otros beneficios</h3>
        <div className="layout8">
          <div>
            <span className="material-icons">emergency</span>
            <p>Dispensario médico (atención gratuita)</p>
          </div>
          <div>
            <span className="material-icons">chair</span>
            <p>Sala de lactancia </p>
          </div>

          <div>
            <span className="material-icons">coffee</span>
            <p>Cafetería y máquina expendedora de alimentos </p>
          </div>
          <div>
            <span className="material-icons">local_parking</span>
            <p>Parqueadero UTPL </p>
          </div>
        </div>
      </div>
  <PresencialPagos></PresencialPagos>
      <BannerGeneral></BannerGeneral>
    </>
  );
}

export default ModeloPresencial;

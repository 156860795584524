import "../css/gallery.css";
import { useState } from "react";

function Gallery(props) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleRight = (e) => {
    e.preventDefault();
    const element = document.getElementById("wrappergallery");
    const newIndex = Math.min(currentIndex + 1, props.gallery.length - 1);
    element.scrollLeft += element.offsetWidth; // Ajuste basado en el ancho visible
    setCurrentIndex(newIndex);
  };

  const handleLeft = (e) => {
    e.preventDefault();
    const element = document.getElementById("wrappergallery");
    const newIndex = Math.max(currentIndex - 1, 0);
    element.scrollLeft -= element.offsetWidth; // Ajuste basado en el ancho visible
    setCurrentIndex(newIndex);
  };

  const handleIndicatorClick = (index) => {
    const element = document.getElementById("wrappergallery");
    element.scrollLeft = index * element.offsetWidth; // Ajuste dinámico
    setCurrentIndex(index);
  };

  return (
    <>
      <div className="wrapper-gallery">
        <div id="wrappergallery">
          {props.gallery.map((novedad, index) => (
            <div className="item-gallery" key={novedad.title}>
              <img src={novedad.url} alt={novedad.title} />
            </div>
          ))}
        </div>
      </div>

      <div className="gallery-indicators">
        <span className="material-icons" onClick={handleLeft}>
          arrow_back
        </span>
        {props.gallery.map((_, index) => (
          <span
            key={index}
            className={`indicator ${
              index === currentIndex ? "active" : ""
            }`}
            onClick={() => handleIndicatorClick(index)}
          >
            ●
          </span>
        ))}
        <span className="material-icons" onClick={handleRight}>
          arrow_forward
        </span>
      </div>
    </>
  );
}

export default Gallery;

import useContentful from "../hooks/use-contenful";
import "../css/academicoffer.css";
import { motion } from "framer-motion";
import { useState } from "react";

function DistanciaVista(props) {
  const [state, setState] = useState(0);

  const [stateheight, setStateHeight] = useState(0);

  const [heightWrapper, setheightWrapper] = useState({
    height: "700px",
    overflow: "hidden",
  });

  let key = "0";
  let facultad = "0";
  let modalidad = "0";
  let query = "";

  if (props.keyword) {
    key = props.keyword;
  } else {
    key = 0;
  }

  if (props.facultad) {
    facultad = props.facultad;
  } else {
    facultad = 0;
  }

  if (props.modalidad) {
    modalidad = props.modalidad;
  } else {
    modalidad = "0";
  }

  if (key === 0 && modalidad === "0") {
    query = `query 
    {
     academicProgramsCollection(where:{
       tipoDePrograma:"Carrera de grado",
       modalidad_contains_some:["En línea", "a Distancia"],
       facultad_contains:"${facultad}",
     }order:[tag_ASC, nombre_ASC]){items{
       sys{id}
       nombre
       modalidad
      facultad
      periodoEnlinea
       duracion
       inicioDeClasesEnLnea
       enlaceAPrograma
       keywords
       tag
       
     } 
     }
     }  
    `;
  } else if (key === 0 && modalidad !== "0") {
    query = `query 
    {
     academicProgramsCollection(where:{
       tipoDePrograma:"Carrera de grado",
       modalidad_contains_all:"${modalidad}"
       facultad_contains:"${facultad}",
     }order:[tag_ASC, nombre_ASC]){items{
       sys{id}
       nombre
       modalidad
      facultad
        periodoEnlinea
       duracion
       inicioDeClasesEnLnea
       enlaceAPrograma
       keywords
       tag
       
     } 
     }
     }  
    `;
  } else {
    query = `query 
      {
       academicProgramsCollection(where:{
         tipoDePrograma:"Carrera de grado",
         modalidad_contains_some:["En línea", "a Distancia"],
         facultad_contains:"${facultad}",
         keywords_contains_all:"${key}"
       }order:[tag_ASC, nombre_ASC]){items{
         sys{id}
         nombre
        facultad
        modalidad
              periodoEnlinea
       duracion
       inicioDeClasesEnLnea
         enlaceAPrograma
         keywords
         tag
         
       } 
       }
       }  
      `;
  }
  function IsPeriodo(data) {
    if (data) {
      return (
        <>
          <p>
            <b>Periodo:</b> {data}
          </p>
        </>
      );
    } else {
      return "";
    }
  }

  function IsInicio(data) {
    if (data) {
      return (
        <>
          <p>
            <b>Inicio de clases:</b> {data}
          </p>
        </>
      );
    } else {
      return "";
    }
  }

  function ViewGrid() {
    setState(0);
  }

  function ViewList() {
    setState(1);
  }
  function handleActiveMore() {
    setheightWrapper({ height: "auto", overflow: "none" });
    setStateHeight(1);
  }

  function IsModalidad(modalidad) {
    return (
      <p>
        <b>Modalidad: </b>
        {modalidad[0].toLowerCase()}
      </p>
    );
  }

  function IsTag(data) {
    if (data && data.trim && data.trim() !== "") {
      return (
        <p className="tag-cupos">{data}</p>
      );
    }
    return null; // Renderiza nada si data está vacío
  }
  
  let { data, errors } = useContentful(query);
  if (errors)
    return <span>{errors.map((error) => error.message).join(",")}</span>;
  if (!data) return <p>Loading...</p>;

  return (
    <>
      <div className="wrapper-view">
        {state === 0 && (
          <div>
            <p onClick={ViewList}>
              Vista de lista <span class="material-icons">view_list</span>
            </p>
          </div>
        )}
        {state === 1 && (
          <div>
            <p onClick={ViewGrid}>
              Vista de cuadrícula <span class="material-icons">grid_view </span>
            </p>
          </div>
        )}
      </div>
      <br></br>

      {state === 0 && (
        <>
          <div style={heightWrapper}>
            <div className="wrapper-academicoffer">
              {data?.academicProgramsCollection.items?.map((info) => (
                <>
                  <div key={info.sys.id}>
                    <div className="layout8">
                      {IsTag(info.tag)}

                      <h3>{info.nombre}</h3>

                      {IsModalidad(info.modalidad)}
                      <p>
                        <b>Duración:</b> {info.duracion}
                      </p>
                      <p>
                        <b>Facultad:</b> {info.facultad}
                      </p>

                      <p>{IsPeriodo(info.periodoEnlinea)}</p>
                      <p>{IsInicio(info.inicioDeClasesEnLnea)}</p>

                      <div className="wrapper-link">
                        <a
                          className="link-offer"
                          href={info.enlaceAPrograma}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Ver programa
                          <span className="material-icons">
                            arrow_forward_ios{" "}
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>

          {stateheight === 0 && (
            <motion.div
              initial={{ x: -100, opacity: 0 }}
              whileInView={{
                x: 0,
                opacity: 1,
                transition: {
                  type: "spring",
                  stiffness: 200,
                },
              }}
            >
              <div className="section-moreinfo">
                <div className="wrapper-moreinfo" onClick={handleActiveMore}>
                  Ver todas las carreras{" "}
                  <span className="material-icons">add</span>
                </div>
              </div>
            </motion.div>
          )}
        </>
      )}

      {state === 1 && (
        <div className="wrapper-academicofferlist layout9">
          {data?.academicProgramsCollection.items?.map((info) => (
            <>
              <div key={info.sys.id}>
                <a href={info.enlaceAPrograma} target="_blank" rel="noreferrer">
                  <span class="material-icons">chevron_right</span>{" "}
                  {info.nombre}
                </a>
              </div>
            </>
          ))}
        </div>
      )}
    </>
  );
}

export default DistanciaVista;
